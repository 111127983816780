<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >

      <b-row
        class="border-bottom"
      >
        <b-col
          cols="12"
          md="6"
          offset-md="6"
        >
          <ValidationObserver
            ref="formSynchronizer"
            v-slot="{ errors: formErrors }"
          >
            <b-row>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="start_date"
                  name="start date"
                  rules="required"
                >
                  <b-form-group
                    label="Start Date"
                    label-for="synchronizeStartDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeStartDate"
                      v-model="synchronize.start_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :max="synchronize.end_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="end_date"
                  name="end date"
                  rules="required"
                >

                  <b-form-group
                    label="End Date"
                    label-for="synchronizeEndDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeEndDate"
                      v-model="synchronize.end_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :min="synchronize.start_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1 pb-2 d-flex align-items-end"
              >
                <b-container
                  fluid
                >
                  <b-row>
                    <b-col
                      cols="6"
                      class="pr-1 pl-0"
                    >
                      <b-overlay
                        :show="state.synchronizing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="success"
                        class="p-0"
                      >
                        <b-button
                          :variant="`${state.synchronizing ? 'outline-dark' : 'success'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="synchronizeProducts"
                        >
                          Sync
                        </b-button>
                      </b-overlay>
                    </b-col>
                    <b-col
                      cols="6"
                      class="px-0"
                    >
                      <b-overlay
                        :show="state.importing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="warning"
                        :class="[
                          'p-0', {
                            'mb-3 pb-1': (
                              Object.keys(formErrors).filter(
                                error => formErrors[error]?.length > 0
                              ).length > 0
                            )
                          }
                        ]"
                      >
                        <b-button
                          :variant="`${state.importing ? 'outline-dark' : 'warning'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="importProducts"
                        >
                          Import
                        </b-button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>

      </b-row>

      <b-row
        class="mt-2"
      >

        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterProductSearch"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filterProductSearch"
              v-model="tableProducts.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group
            label="Category"
            label-for="filterCategory"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="tableProducts.selected.category"
              placeholder="search category here"
              label="category_name"
              class="vs-white"
              clearable
              append-to-body
              input-id="filterCategory"
              :options="(tableProducts.options.categories)"
              :loading="(tableProducts.fetching.categories)"
              :disabled="(tableProducts.busy || tableProducts.fetching.categories)"
              :calculate-position="calculateDropPosition"
            >
              <template #option="{ category_code, category_name }">
                <div class="d-flex flex-column flex-md-row py-1">
                  <div class="flex-grow-1">
                    {{ category_name }}
                  </div>
                  <strong class="min-w-50px">{{ category_code }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available category
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableProducts"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableProducts.busy"
            :filter="tableProducts.filter"
            :fields="tableProducts.fields"
            :stacked="tableProducts.stacked"
            :sort-by.sync="tableProducts.sortBy"
            :sort-desc.sync="tableProducts.sortDesc"
            :sort-direction="tableProducts.sortDirection"
            :filter-included-fields="tableProducts.filterOn"
            :current-page="tableProducts.currentPage"
            :per-page="tableProducts.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableProducts, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editProduct(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableProducts.perPage"
            :options="tableProducts.pageOptions"
            :disabled="tableProducts.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableProducts.currentPage"
            :total-rows="tableProducts.totalRows"
            :per-page="tableProducts.perPage"
            :disabled="tableProducts.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>

    </b-container>

    <b-modal
      id="modalProduct"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      title="Edit Product"
      aria-hidden="false"
    >
      <ValidationObserver
        ref="formProduct"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Code"
                label-for="product_code"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="product_code"
                  v-model="product.product_code"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  placeholder="no product code"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Name"
                label-for="product_name"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="product_name"
                  v-model="product.product_name"
                  type="text"
                  autocomplete="off"
                  maxlength="255"
                  placeholder="no product name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="category"
                name="category"
                rules="required"
              >
                <b-form-group
                  label="Category"
                  label-for="category"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.category"
                    placeholder="search category here"
                    label="category_name"
                    clearable
                    append-to-body
                    input-id="category"
                    :options="(options.categories)"
                    :loading="(fetching.categories)"
                    :disabled="(state.busy || fetching.categories)"
                    :calculate-position="calculateDropPosition"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  >
                    <template #option="{ category_code, category_name }">
                      <div class="d-flex flex-column flex-md-row py-1">
                        <div class="flex-grow-1">
                          {{ category_name }}
                        </div>
                        <strong class="min-w-50px">{{ category_code }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available category
                    </template>
                  </v-select>
                  <div
                    v-if="(errors.length > 0)"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="product.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateProduct"
        >
          Update Record
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SAdminProduct, SSharedList } from '@/services'

export default {
  name: 'AdminProducts',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Products'
  }),

  data () {
    return {
      state: {
        busy: false,
        synchronizing: false,
        importing: false
      },
      synchronize: {
        start_date: null,
        end_date: null
      },
      fetching: {
        categories: false
      },
      options: {
        categories: []
      },
      selected: {
        category: null
      },
      product: {
        id: null,
        category: null,
        product_code: null,
        product_name: null,
        active: 1
      },
      tableProducts: {
        busy: false,
        fetching: {
          categories: false
        },
        options: {
          categories: []
        },
        selected: {
          category: null
        },
        filter: {
          search: null,
          category: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 7, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'product_code', class: 'min-w-175px' },
          { mobile: 3, key: 'product_name', class: 'min-w-175px' },
          { mobile: 3, key: 'category.category_name', label: 'Category', class: 'min-w-175px' },
          { mobile: 4, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 5, key: 'valid', class: 'text-center' },
          { mobile: 6, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'selected.category' (category) {
      this.product.category = category?.id || null
    },

    'tableProducts.selected.category' (category) {
      this.tableProducts.filter.category = category?.id || null
    }
  },

  mounted () {
    core.index()
    this.getFilterCategories()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableProducts.busy = true
      return await SAdminProduct.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_category: filter.category
      }).then(({ data }) => {
        this.tableProducts.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableProducts.totalRows = 0
        return []
      }).finally(() => {
        this.tableProducts.busy = false
      })
    },

    async synchronizeProducts () {
      await this.$refs.formSynchronizer.validate().then(
        validSynchronizer => {
          if (!validSynchronizer) {
            return this.swalInvalid()
          }

          this.state.synchronizing = true
          return SAdminProduct.sync(this.synchronize).then(({ data: { message } }) => {
            this.swalSuccess(message).then(() => {
              this.synchronize.start_date = null
              this.synchronize.end_date = null
              this.$refs.formSynchronizer.reset()
              this.$refs.tableProducts.refresh()
            })
          }).finally(() => {
            this.state.synchronizing = false
          })
        }
      )
    },

    importProducts () {
      this.state.importing = true
      return SAdminProduct.import().then(({ data: { message } }) => {
        this.swalSuccess(message).then(() => {
          this.$refs.tableProducts.refresh()
        })
      }).finally(() => {
        this.state.importing = false
      })
    },

    async getFilterCategories () {
      this.tableProducts.fetching.categories = true
      return await SSharedList.getCategories().then(({ data }) => {
        this.tableProducts.options.categories = data
      }).catch(() => {
        this.tableProducts.options.categories = []
      }).finally(() => {
        this.tableProducts.fetching.categories = false
      })
    },

    async getCategories () {
      this.fetching.categories = true
      return await SSharedList.getCategories().then(({ data }) => {
        this.options.categories = data
      }).catch(() => {
        this.options.categories = []
      }).finally(() => {
        this.fetching.categories = false
      })
    },

    editProduct (product) {
      this.product.id = product.id
      this.product.product_code = product.product_code
      this.product.product_name = product.product_name
      this.product.active = product.active

      this.getCategories().finally(() => {
        this.selected.category = QUERY.find(this.options.categories, {
          id: Number(product.category_id)
        })
      })

      this.$bvModal.show('modalProduct')
    },

    async validateProduct () {
      await this.$refs.formProduct.validate().then(
        async validProduct => {
          if (!validProduct) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: '<h6>Save your changes on this Product?</h6>',
            preConfirm: () => this.updateProduct()
          })
        }
      )
    },

    async updateProduct () {
      this.state.busy = true
      return await SAdminProduct.put(this.product).then(
        ({ data: { message, product } }) => {
          this.$bvModal.hide('modalProduct')
          this.swalSuccess(message).then(() => {
            const tableProductsRow = QUERY.find(this.$refs.tableProducts.localItems, {
              id: Number(product.id)
            })
            if (tableProductsRow) {
              tableProductsRow.category = product.category
              tableProductsRow.category_id = product.category_id
              tableProductsRow.product_code = product.product_code
              tableProductsRow.product_name = product.product_name
              tableProductsRow.active = product.active
              tableProductsRow.valid = product.valid
              tableProductsRow.updated_at = product.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formProduct.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
